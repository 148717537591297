 /* NAVBAR */
  
 .navbar {
    background-color: #98FF98;
  }
  
  .navbar-nav .nav-link {
    display: flex;
    color: #000000;
    font-size: 1.1rem;
    transition: all 0.5s;
  }
  .navbar-nav .nav-link:hover {
    color: #fff;
  }
  @media screen and (min-width: 1024px) {
    .navbar-nav .nav-item {
      padding: 0 1rem;
    }
    .navbar-brand {
      font-size: 1.5rem;
    }
  }

  /* /////////////// login signup popup */
  #lean_overlay {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: #000;
    display: none;
  }
  
  .popupContainer {
    position: absolute;
    width: 330px;
    height: auto;
    left: 45%;
    top: 60px;
    background: #FFF;
  }
  
  #modal_trigger {
    margin: 40px auto;
    width: 200px;
    display: block;
    border: 1px solid #DDD;
    border-radius: 4px;
  }
  
  .btn {
    padding: 10px 20px;
    background: #F4F4F2;
  }
  
  .btn_red {
    background: #ED6347;
    color: #FFF;
  }
  
  .btn:hover {
    background: #98FF98;
    color: #fff;
  }
  
  .btn_red:hover {
    background: #98FF98;
  }
  
  a.btn {
    color: #666;
    text-align: center;
    text-decoration: none;
  }
  
  a.btn_red {
    color: #FFF;
  }
  
  .one_half {
    width: 50%;
    display: block;
    float: left;
  }
  
  .one_half.last {
    width: 45%;
    margin-left: 5%;
  }
  
  
  /* Popup Styles*/
  
  .popupHeader {
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .popupHeader {
    background: #F4F4F2;
    position: relative;
    padding: 10px 20px;
    border-bottom: 1px solid #DDD;
    font-weight: bold;
  }
  
  .popupHeader .modal_close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 15px;
    background: #E4E4E2;
    cursor: pointer;
    color: #aaa;
    font-size: 16px;
  }
  
  .popupBody {
    padding: 20px;
  }
  
  