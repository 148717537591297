.footer-section {
    display:block;
    width: 100%;
    height: 250px;
    display: flex;
    border-top-right-radius: 50px;
    background-color: hsl(240, 10%, 16%);
    position: absolute;
  }
  .footer-logo,
  #footer-logo{
    top:20px;
    left:6%;
    position:absolute;
  }
  .footer-nav{
    text-align:center;
    margin:0 auto;
    width:70%;
    height:180px;
    background-color:transparent;
    position:relative;
    display:flex;
  }
  .footer-projects,
  .footer-company,
  .footer-connect{
    padding:20px;
    width:70px;
    height:180px;
    margin:40px;
    left:15%;
    position:relative;
     line-height:20px;
  }
  .footer-section a {
    text-decoration: none;
    color: hsl(240, 2%, 79%);
    cursor: pointer;
    font-size:0.7em;
  }
  .footer-section a:hover {
    opacity: 0.7;
  }
  .footer_text {
    margin-bottom: 20px;
    color:white;
    font-size:0.9em;
  }
  @media screen and (max-width: 600px){
    .footer-section {
      top:3600px;
    display:block;
    width: 100%;
    height: 750px;
    border-top-right-radius: 50px;
    background-color: hsl(240, 10%, 16%);
    position: absolute;
  }
  .footer-logo,
  #footer-logo{
    margin:0 auto;
    top:20px;
    left:6%;
    position:absolute;
  }
  .footer-nav{
    text-align:center;
    width:50%;
    height:480px;
    background-color:transparent;
    position:relative;
    display:flex;
    flex-wrap:wrap;
  }
  .footer-projects,
  .footer-company,
  .footer-connect{
    padding:20px;
    width:70px;
    height:180px;
    margin:40px;
    left:15%;
    position:relative;
     line-height:20px;
  }
  .footer-section a {
    text-decoration: none;
    color: hsl(240, 2%, 79%);
    cursor: pointer;
    font-size:0.7em;
  }
  .footer-section a:hover {
    opacity: 0.7;
  }
  .footer_text {
    margin-bottom: 20px;
    color:white;
    font-size:0.9em;
  }
  }