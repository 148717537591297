/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/*===== VARIABLES CSS =====*/
:root{
  --header-height: 3rem;

  /*===== Colors =====*/
  --first-color: #3E0E12;
  --first-color-dark: #2F0A0D;
  --text-color: #524748;
  --first-color-light: #7B6F71;
  --first-color-lighten: #FBF9F9;

  /*===== Font and typography =====*/
  --body-font: 'Poppins', sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*===== Font weight =====*/
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;

  /*===== Margins =====*/
  --mb-1: .5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /*===== z index =====*/
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}
  
@media screen and (min-width: 768px){
  :root{
    --biggest-font-size: 4.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*===== BASE =====*/
*,::before,::after{
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  background-color: var(--first-color-lighten);
  color: var(--text-color);
  line-height: 1.6;
}

h1,h2,h3,ul,p{
  margin: 0;
}

h2,h3{
  font-weight: var(--font-semi-bold);
}

ul{
  padding: 0;
  list-style: none;
}

a{
  text-decoration: none;
}

/*===== CLASS CSS =====*/
.section {
  padding: 4rem 0 2rem;
}

.section-title, .section-subtitle {
  text-align: center;
}

.section-title {
  font-size: var(--h1-font-size);
  color: var(--first-color);
  margin-bottom: var(--mb-3);
}

.section-subtitle {
  display: block;
  font-size: var(--smaller-font-size);
  font-weight: var(--font-semi-bold);
}

/*===== LAYOUT =====*/
.bd-container {
  max-width: 1024px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

/*===== BUTTON =====*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: var(--first-color-lighten);
  padding: .75rem 1rem;
  border-radius: .25rem;
  transition: .3s;
}

.button:hover {
  background-color: var(--first-color-dark);
}

/*===== CONTACT ME =====*/
.contact__container {
  row-gap: 2.5rem;
  color: whi;
}

.contact__content {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.contact__box {
  background-color: #7bf17b;
  border-radius: .5rem;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px #7bf17b;
}

.contact__icon, .contact__title {
  color: var(--first-color);
}

.contact__icon {
  font-size: 2rem;
}

.contact__description2{
    font-size: 13px;
}

.contact__title {
  font-size: var(--h3-font-size);
  margin: var(--mb-1) 0;
}

.contact__social {
  color: var(--first-color-light);
  font-size: 1.25rem;
  margin: 0 var(--mb-1);
}

.contact__box:hover {
  box-shadow: 0 6px 8px #45ae45;
}

.contact__inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}

.contact__input, .contact__button {
  outline: none;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

.contact__input {
  width: 100%;
  padding: 1rem;
  border: 2px solid var(--first-color-light);
  color: var(--first-color);
  border-radius: .5rem;
  margin-bottom: var(--mb-1);
}

.contact::placeholder {
  color: var(--first-color-light);
  font-family: var(--body-font);
  font-weight: var(--font-semi-bold);
}

.contact__button {
    background-color: #4cc44c;
  cursor: grab;
  border: none;
}

/*===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
  .contact__form {
    width: 450px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px) {
  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }
}