@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

:root {
    --accent-color: #98FF98;
  }


  .display {
    font-family: "Montserrat", sans-serif;
    font-size: calc(1.01rem + 3vw);
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 20px;
    color: #0b800b;

}

  
  section [class^="container"] {
    padding: 4rem 2rem;
  }
  @media screen and (min-width: 1024px) {
    section [class^="container"] {
      padding: 4rem;
    }
    nav [class^="container"] {
      padding: 0 4rem;
    }
  }
  
  section:not(:first-of-type) {
    text-align: center;
  }
  
  section:nth-child(2n) {
    background-color: #ecf2ff;
  }
  
  .container a {
    color: #000000;
    text-decoration: none;
  }
  .container a:hover {
    color: var(--accent-color);
  }
  
  section .card,
  .btn-outline-dark {
    border: 2px solid #000000;
    box-shadow: 4px 4px #000000;
    transition: all 0.4s;
  }
  .btn-outline-dark:hover {
    box-shadow: 4px 4px var(--accent-color);
  }
  
 
  
  /* HERO */
  
  section.hero {
    padding-top: 72px;
  }
  @media screen and (max-width: 576px) {
    section.hero {
      text-align: center;
    }
    section.hero img {
      width: 80%;
      margin: 0.5rem 0;
    }
  }
  
  /* SERVICES */
  
  section.services i {
    font-size: 2rem;
    margin: 1rem auto 0;
    border: 2px solid #000000;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color);
  }
  
  section .card {
    max-width: 22rem;
    margin-inline: auto;
  }
  /* ABOUT */
  @media screen and (min-width: 1024px) {
    section.about .container,
    section.testimonials .container {
      width: 60%;
    }
  }
  
  
  /* TESTIMONIALS */
/*   
  section.testimonials .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }
  
  section.testimonials .carousel-item p {
    max-width: 80%;
    border-left: 5px solid var(--accent-color);
    padding-left: 1rem;
    text-align: left;
  }
  section.testimonials .carousel-item h5 {
    text-align: left;
  }
  section.testimonials .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  section.testimonials .carousel.carousel-fade .carousel-item {
    transition: opacity 0.5s;
  } */
  /* CONTACT */
  section.contact .social-media a {
    padding: 0 0.5rem;
    font-size: 1.3rem;
  }
  